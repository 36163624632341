import useUserActionState from "@/hooks/use-user-action-state";
import useUpdateUserAction from "./use-update-user-action";

const useUserActionUpdateSaved = () => {
  const { savedMhids, setSavedMhids, setSavedFulfilled } = useUserActionState();

  const { toggleMedia } = useUpdateUserAction({
    mhids: savedMhids,
    setMhids: setSavedMhids,
    setFulfilled: setSavedFulfilled,
    apiRoute: "saved",
  });

  return { toggleSaved: toggleMedia };
};

export default useUserActionUpdateSaved;
