import React from "react";
import styled, { css } from "styled-components";
import { ContentType } from ".";
import { animated, useTransition } from "react-spring";

import useTheme from "../../hooks/use-theme";

import Gauge from "../controls/gauge";
import Pluralize from "../controls/pluralize";
import { getReleaseYear } from "../../util/get-metadata";
import Button, { ButtonVariant } from "../controls/button";
import Stack, {
  StackDirection,
  StackSpacing,
  StackAlignment,
} from "@/components/stack";

import BookmarkSvg from "@/components/assets/icons/icon-bookmark";

import useUserActionState from "@/hooks/use-user-action-state";
import { checkRating, checkHasMedia } from "@/util/check-user-action-data";
import { contentTypeInfo } from "../../util/content-types";

const InfoBackground = styled.div`
  color: ${(props) => props.theme.Color.PrimaryColor};
  padding: ${(props) => props.theme.Padding}px;
  flex-direction: column;
  align-items: baseline;

  /* These styles ensure that all poster heights of siblings are the same */
  flex: 1;
  background-color: ${(props) => props.theme.Color.TransparentLight};
  box-sizing: border-box;

  display: flex;
`;

const TitleLineHeight = 18;

type PosterTitleProps = {
  $lineClamp?: number;
};

// MaxHeight is for Safari Mobile (this issue can only be seen on an ACTUAL iphone or the XCode simulator, and cannot be seen if using the simulator within the Safari browser) - Since we are clamping the title anyway, using a height is okay here (not max-height because that will make 1 line titles shorter than 2 line titles)
const MaxHeight = styled.div<PosterTitleProps>`
  ${(props) => css`
    height: ${TitleLineHeight * props.$lineClamp}px;
  `}
`;

const PosterTitle = styled.h3<PosterTitleProps>`
  ${(props) => css`
    ${props.theme.LineClamp(props.$lineClamp)}
    ${props.theme.FontHeader()}
    color: inherit; /* In case we are passing in a link */
    font-size: 16px;
    margin: 0;
    white-space: normal;
  `}
`;

const PosterDescription = styled.span<PosterTitleProps>`
  ${(props) => css`
    color: ${props.theme.Color.SecondaryColor};
    font-size: 14px;
    white-space: normal;

    display: flex;
    align-items: flex-end;

    ${props.$lineClamp &&
    css`
      ${props.theme.LineClamp(props.$lineClamp)}
    `}
  `}
`;

// We need to define the height on the bookmark icon Stack Element or it sits too low vertically
type StackBookmarkStyles = {
  $setFirstItemHeight?: boolean;
};
const StackStyled = styled(Stack)<StackBookmarkStyles>`
  ${(props) =>
    props.$setFirstItemHeight &&
    css`
      & > div:first-of-type {
        height: 10px;
      }
    `}
`;

const BookmarkIcon = styled(BookmarkSvg)`
  margin-top: 1px;
  width: 8px;
`;

const PosterInformationBar = ({ item, type, getDescription }) => {
  const { ratedDict, seenMhids, savedMhids } = useUserActionState();
  const rawRating = checkRating({
    ratingDict: ratedDict,
    mhid: item.mhid,
  });
  const isSeen = checkHasMedia({ mhidsArray: seenMhids, mhid: item.mhid });

  const isSaved = checkHasMedia({
    mhidsArray: savedMhids,
    mhid: item.mhid,
  });

  const gaugePopIn = useTransition(!!rawRating || isSeen, {
    from: { scale: 0 },
    enter: { scale: 1 },
    leave: { scale: 0 },
    config: {
      mass: 5,
      tension: 400,
      friction: 35,
      clamp: !rawRating && !isSeen,
    },
  });

  const savedPopIn = useTransition(isSaved, {
    from: { scale: 0 },
    enter: { scale: 1 },
    leave: { scale: 0 },
    config: {
      mass: 5,
      tension: 400,
      friction: 35,
      clamp: !isSaved,
    },
  });

  let posterContent;
  const titleLineClamp = 2;
  if (typeof getDescription === "function") {
    posterContent = (
      <PosterDescription $lineClamp={2}>
        {getDescription({ item })}
      </PosterDescription>
    );
  } else if (type === ContentType.CONTRIBUTOR) {
    posterContent = (
      <PosterDescription $lineClamp={1}>
        <Pluralize amount={item.count} singular="Title" plural="Titles" />
      </PosterDescription>
    );
  } else {
    posterContent = (
      <Stack
        direction={StackDirection.ROW}
        horizontalAlignment={StackAlignment.SPACE_BETWEEN}
      >
        <PosterDescription $lineClamp={1}>
          {getReleaseYear(item)}
        </PosterDescription>

        <StackStyled
          direction={StackDirection.ROW}
          spacing={StackSpacing.SMALL}
          horizontalAlignment={StackAlignment.SPACE_BETWEEN}
          verticalAlignment={StackAlignment.CENTER}
          $setFirstItemHeight={isSaved}
        >
          {savedPopIn(
            ({ scale }, item, transitionState) =>
              item && (
                <animated.div
                  key={transitionState.ctrl.id}
                  style={{ transform: scale.to((s) => `scale(${s})`) }}
                >
                  <BookmarkIcon />
                </animated.div>
              )
          )}

          {gaugePopIn(
            ({ scale }, item, transitionState) =>
              item && (
                <animated.div
                  key={transitionState.ctrl.id}
                  style={{ transform: scale.to((s) => `scale(${s})`) }}
                >
                  <Gauge
                    onSelect={() => {}}
                    initialRawValue={rawRating}
                    size={22}
                    thickness={0.4}
                    disabled
                    noText
                    showQuestionMark={!rawRating}
                  />
                </animated.div>
              )
          )}
        </StackStyled>
      </Stack>
    );
  }

  const theme = useTheme();
  const TitleWrapper = ({ children }) => {
    const href =
      type === ContentType.CONTRIBUTOR
        ? `/spotlight/person/${item.altId.slice(6)}`
        : //remove the "mhmov-" or "mhsss" from the sltId
          `/spotlight/${
            contentTypeInfo.find((o) => o.contentType === type).path
          }/${item.altId.slice(6)}`;
    return (
      <MaxHeight $lineClamp={titleLineClamp}>
        <Button
          {...{ href }}
          variant={ButtonVariant.Text}
          cta
          color={theme.Color.PrimaryColor}
        >
          {children}
        </Button>
      </MaxHeight>
    );
  };

  return (
    <InfoBackground>
      <Stack spacing={StackSpacing.SMALL}>
        <TitleWrapper>
          <PosterTitle $lineClamp={titleLineClamp}>{item?.name}</PosterTitle>
        </TitleWrapper>

        {posterContent}
      </Stack>
    </InfoBackground>
  );
};
export default PosterInformationBar;
PosterInformationBar.Background = InfoBackground;
PosterInformationBar.Title = PosterTitle;
PosterInformationBar.Description = PosterDescription;
