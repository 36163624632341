import React, { useCallback, useMemo } from "react";
import styled, { css } from "styled-components";
import { useRouter } from "next/router";

import Button, { ButtonVariant } from "../controls/button";
import CloseButton from "../controls/close-button";
import Gauge from "../controls/gauge";
import { OverlayContainer } from "../controls/overlay-container";
import WrappedItems from "../controls/wrapped-items";
import Stack, { StackSpacing, StackAlignment } from "../stack";

import SizedIcon from "../assets/sized-icon";

import useTheme from "@/hooks/use-theme";
import useLocalSettings from "@/hooks/use-local-settings";
import useUserActionState from "@/hooks/use-user-action-state";
import useUserActionUpdateSaved from "@/hooks/use-user-action-update-saved";
import useUserActionUpdateRating from "@/hooks/use-user-action-update-rating";
import useUserActionUpdateUninterested from "@/hooks/use-user-action-update-uninterested";

import useAuth from "@/hooks/use-auth";
import useCarouselMetadata from "@/hooks/use-carousel-metadata";
import useClientApi from "@/hooks/use-client-api";
import { MHMediaObject } from "../../@types/api";
import { checkHasMedia, checkRating } from "@/util/check-user-action-data";

import { getWatchUrlForItem } from "../../util/sources";

const RatingHeaderContainer = styled.div`
  color: ${(props) => props.theme.Color.SecondaryColor};
`;

type ContainerProps = {
  $firstItemRight?: boolean;
};
const StackWithBackgroundColor = styled(Stack)<ContainerProps>`
  background: ${(props) => props.theme.Color.PosterRatingBackgroundColor};

  ${(props) =>
    props.$firstItemRight &&
    css`
      & > div:first-child {
        align-self: flex-end;
      }
    `}
`;

const RemoveFromHistoryButtonContainer = styled.div`
  margin: 7px 7px 5px 0;
`;

type TitleActionProps = {
  item: MHMediaObject | { [k: string]: null };
  contentIndex: number;
  onPosterClicked?: (props: {
    item: MHMediaObject | { [k: string]: null };
  }) => void;
};
const ContentPosterActions = ({
  item,
  contentIndex,
  onPosterClicked,
}: TitleActionProps) => {
  const clientApi = useClientApi();
  const auth = useAuth();
  const theme = useTheme();
  const router = useRouter();

  const isLoggedIn = auth.user ? true : false;
  const showCloseButton = router.query?.view?.includes("history");

  const { settings } = useLocalSettings();
  const { url, source } = useMemo(
    () =>
      getWatchUrlForItem(item, settings.selectedSources) || {
        url: null,
        source: null,
      },
    [item, settings.selectedSources]
  );

  const siloData = useCarouselMetadata();
  const { savedMhids, ratedDict, uninterestedMhids } = useUserActionState();
  const { toggleRating } = useUserActionUpdateRating();
  const { toggleSaved } = useUserActionUpdateSaved();
  const { toggleUninterested } = useUserActionUpdateUninterested();

  const isSaved = checkHasMedia({
    mhidsArray: savedMhids,
    mhid: item.mhid,
  });

  const initialRawRating = checkRating({
    ratingDict: ratedDict,
    mhid: item.mhid,
  });

  const isUninterested = checkHasMedia({
    mhidsArray: uninterestedMhids,
    mhid: item.mhid,
  });

  const onSelectGaugeValue = ({ raw, rating }) => {
    toggleRating({
      item,
      ratingValues: { rawValue: raw, rating },
      action: "add",
    });
  };

  const onRemoveGaugeValue = () => {
    toggleRating({ item, action: "remove" });
  };

  const handlePlayClicked = useCallback(async () => {
    try {
      await clientApi("/api/profile/action/click", {
        method: "POST",
        data: {
          contentMhid: item.mhid,
          contentIndex,
          url,
          source,
          originatingPage: window.location.pathname,
          siloData,
        },
      });
    } catch (err) {
      // TODO: handle the failure case
      console.log("Play Click error", err);
    }
  }, [clientApi, item, contentIndex, url, source, siloData]);

  const iconProps = {
    size: 32,
    changeColor: true,
    changeBgColor: true,
  };

  return (
    /* The first Stack separates the remove [X] button from the default poster content - we want SMALL spacing between those two elements when it exists */
    <StackWithBackgroundColor
      spacing={StackSpacing.SMALL}
      fullHeight
      $firstItemRight={showCloseButton}
      verticalAlignment={!showCloseButton && StackAlignment.CENTER}
    >
      {showCloseButton && (
        <RemoveFromHistoryButtonContainer>
          <CloseButton
            size={16}
            onClick={() => onPosterClicked({ item })}
            tooltipText="Remove from History"
          />
        </RemoveFromHistoryButtonContainer>
      )}

      {/* The second Stack separates the gauge/its helper text from the action icons */}
      <Stack
        spacing={StackSpacing.MEDIUM}
        horizontalAlignment={StackAlignment.CENTER}
        verticalAlignment={StackAlignment.CENTER}
      >
        {/* The third Stack separates the gauge from its own helper text - so these items need minimal spacing */}
        <Stack
          spacing={StackSpacing.SMALL}
          horizontalAlignment={StackAlignment.CENTER}
          verticalAlignment={StackAlignment.CENTER}
        >
          <RatingHeaderContainer>
            {initialRawRating ? (
              <Button variant={ButtonVariant.Text} onClick={onRemoveGaugeValue}>
                Remove Rating
              </Button>
            ) : (
              "Add a Rating"
            )}
          </RatingHeaderContainer>
          <Gauge
            onSelect={onSelectGaugeValue}
            initialRawValue={initialRawRating}
            disabled={!isLoggedIn}
          />

          {!isLoggedIn && (
            <OverlayContainer>
              <Button href="/auth/signup" cta>
                Sign Up
              </Button>
            </OverlayContainer>
          )}
        </Stack>

        <WrappedItems>
          <SizedIcon.Button
            icon={SizedIcon.IconType.PLAY}
            name={url ? "Watch" : "Not Available to Stream"}
            target="_blank"
            rel="noopener noreferrer"
            onClick={isLoggedIn ? handlePlayClicked : () => {}}
            href={url}
            disabled={!url}
            {...iconProps}
          />

          <SizedIcon.Button
            icon={SizedIcon.IconType.BOOKMARK}
            name={
              !isLoggedIn
                ? "Sign Up to Add to Watchlist"
                : isSaved
                ? "Remove from Watchlist"
                : "Save to Watchlist"
            }
            tooltipWidth={170}
            isSelected={isSaved}
            bgColor={theme.Color.ButtonHover}
            disabled={!isLoggedIn}
            onClick={() => toggleSaved({ item })}
            {...iconProps}
          />

          <SizedIcon.Button
            icon={SizedIcon.IconType.NOT_ALLOWED}
            name={
              !isLoggedIn
                ? "Sign Up to Hide Suggestion"
                : isUninterested
                ? "Show as Suggestion"
                : "Don't Suggest Again"
            }
            tooltipWidth={150}
            isSelected={isUninterested}
            bgColor={theme.Color.ButtonHover}
            disabled={!isLoggedIn}
            onClick={() => toggleUninterested({ item })}
            {...iconProps}
          />
        </WrappedItems>
      </Stack>
    </StackWithBackgroundColor>
  );
};

export default ContentPosterActions;
