import React from "react";
import Svg from "../svg";

type IconProps = {
  className?: string;
  preserveAspectRatio?: string;
};
const IconStats = ({ className, preserveAspectRatio }: IconProps) => (
  <Svg
    width="22"
    height="22"
    className={className}
    preserveAspectRatio={preserveAspectRatio}
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M2.64 8.36V22H0V8.36h2.64zM7.48 0v22H4.84V0h2.64zm4.84 11v11H9.68V11h2.64zm4.84-5.72V22h-2.64V5.28h2.64zM22 8.36V22h-2.64V8.36H22z"
    />
  </Svg>
);

export default IconStats;
