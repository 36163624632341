import { RequireAtLeastOne } from "@/util/typescript";
import { useTheme } from "styled-components";
import { useRef, useEffect, useState, useMemo, RefObject } from "react";

import usePageSize from "./use-page-size";

export type Breakpoint = {
  count: number;
  width: number;
};
export type BoundingBoxSize = {
  width: number;
  height: number;
  widthRatio: number;
  heightRatio: number;
};

type BreakpointParams = RequireAtLeastOne<
  {
    minWidth: number;
    maxWidth: number;
    padding: number;
  },
  "minWidth" | "maxWidth"
>;
function useBreakpoints({ minWidth, maxWidth, padding }: BreakpointParams) {
  const theme = useTheme();
  const pageSize = usePageSize();

  const boundingBoxRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(
    null
  );
  const [boundingBoxMeasurements, setBoundingBoxMeasurements] = useState<
    Omit<BoundingBoxSize, "widthRatio" | "heightRatio">
  >({
    width: 1,
    height: 1,
  });

  const boundingBoxSize: BoundingBoxSize = useMemo(() => {
    const { width, height } = boundingBoxMeasurements;
    return {
      width,
      height,
      widthRatio: width / pageSize.width,
      heightRatio: height / pageSize.height,
    };
  }, [pageSize, boundingBoxMeasurements]);

  useEffect(() => {
    function handleResize() {
      const { width, height } = boundingBoxRef.current.getBoundingClientRect();

      setBoundingBoxMeasurements({
        width,
        height,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  const currentBreakpoint = useMemo(() => {
    const minColumnCount = minWidth
      ? Math.max(Math.floor(theme.MinWidthSmall / minWidth), 1)
      : 1;

    const maxMeasure = minWidth ? padding * 2 + minWidth : maxWidth;

    if (
      boundingBoxMeasurements.width === 1 &&
      boundingBoxMeasurements.height === 1
    ) {
      // in this case the div has not ben measured yet, so
      // we can take a guess based off of the page size and adjust once measure

      if (pageSize.width > 1) {
        const numColumns = Math.floor(pageSize.width / maxMeasure);
        return {
          count: numColumns,
          width: pageSize.width / numColumns - 2 * padding,
        };
      }

      return { count: minColumnCount, width: padding * 2 };
    }
    const maxColumnCount = maxWidth
      ? Math.ceil(boundingBoxMeasurements.width / (maxWidth + padding * 2))
      : Math.floor(boundingBoxMeasurements.width / (padding * 2 + minWidth));

    const numColumns = Math.max(maxColumnCount, minColumnCount);

    const widthPlusNegativeMargins =
      boundingBoxMeasurements.width + 2 * padding;
    const widthPlusPadding = widthPlusNegativeMargins / numColumns;

    return {
      count: numColumns,
      width: widthPlusPadding - 2 * padding,
    };
  }, [
    boundingBoxMeasurements,
    minWidth,
    maxWidth,
    padding,
    theme,
    pageSize.width,
  ]);

  return [currentBreakpoint, boundingBoxSize, boundingBoxRef] as [
    Breakpoint,
    BoundingBoxSize,
    RefObject<HTMLDivElement>
  ];
}

export default useBreakpoints;
