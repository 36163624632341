import React from "react";
import styled, { css } from "styled-components";

import useTheme from "../../hooks/use-theme";

import Button from "./button";
import Tooltip, { TooltipPosition } from "../controls/tooltip";

type ContainerProps = {
  $isFaded?: boolean;
};
const Container = styled.div<ContainerProps>`
  ${(props) => css`
    display: inline-block;
    opacity: ${props.$isFaded ? 0.3 : 1};
    vertical-align: top;
  `}
`;

export type ButtonIconProps = {
  Icon: any;
  bgColor?: string;
  name?: string;
  onClick?: any;
  isSelected?: boolean;
  tooltipPosition?: any;
  tooltipWidth?: number;
  size?: number;
  href?: string;
  target?: string;
  rel?: string;
  disabled?: boolean;
  isFaded?: boolean;
  changeBgColor?: boolean;
  changeColor?: boolean;
};
const ButtonIcon = ({
  Icon,
  bgColor,
  name,
  onClick,
  isSelected,
  tooltipPosition = TooltipPosition.BottomCenter,
  tooltipWidth,
  href,
  target,
  rel,
  disabled,
  isFaded,
  changeBgColor = false,
  changeColor = false,
}: ButtonIconProps) => {
  const theme = useTheme();

  let _backgroundColor = theme.Color.TransparentLight;
  let _color = theme.Color.PrimaryColor;
  if (isSelected) {
    _backgroundColor = bgColor;
    _color = theme.Color.ButtonHoverTextColor;
  }

  const linkProps = href ? { href, target, rel } : {};

  return (
    <Tooltip text={name} position={tooltipPosition} width={tooltipWidth}>
      <Container $isFaded={isFaded}>
        <Button
          color={_color}
          bgColor={_backgroundColor}
          isActive={isSelected}
          changeBgColor={changeBgColor}
          changeColor={changeColor}
          width={40}
          onClick={!disabled ? onClick : () => {}}
          {...linkProps}
          disabled={disabled}
        >
          <Icon />
        </Button>
      </Container>
    </Tooltip>
  );
};

export default ButtonIcon;
