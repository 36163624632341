import useUserActionState from "@/hooks/use-user-action-state";
import useUpdateUserAction from "./use-update-user-action";

const useUserActionUpdateRating = () => {
  const {
    seenMhids,
    setSeenMhids,
    setSeenFulfilled,
    ratedDict,
    setRatedDict,
  } = useUserActionState();

  const { toggleMedia } = useUpdateUserAction({
    mhids: seenMhids,
    setMhids: setSeenMhids,
    setFulfilled: setSeenFulfilled,
    ratedDict,
    setRatedDict,
    apiRoute: "rated",
  });
  return { toggleRating: toggleMedia };
};

export default useUserActionUpdateRating;
