import React from "react";
import { darken, desaturate } from "polished";

import Svg from "../svg";
import useTheme from "../../../hooks/use-theme";

const renderDarkenedColor = (color) => desaturate(0.1, darken(0.12, color));

type IconProps = {
  className?: string;
};
const CheckSvg = ({ className }: IconProps) => {
  const theme = useTheme();
  return (
    <Svg viewboxWidth="16" viewboxHeight="18" className={className}>
      <g fill="none" fillRule="evenodd">
        <path
          fill={renderDarkenedColor(theme.Color.SubtleGreen)}
          d="M7.804 18L0 12.3V5.558L7.804 0l7.803 5.558V12.3z"
        />
        <path
          fill={theme.Color.SubtleGreen}
          d="M7.804 16.071L0 11.931V5.286l7.804-4 7.803 4v6.645z"
        />
        <path
          fill={theme.Color.PrimaryColor}
          d="M3.603 9.356a.392.392 0 0 1-.103-.25c0-.07.034-.177.103-.248l.478-.498a.322.322 0 0 1 .479 0l.034.036 1.88 2.097a.16.16 0 0 0 .24 0l4.58-4.942h.035a.322.322 0 0 1 .479 0l.478.498a.353.353 0 0 1 0 .498l-5.47 5.902a.305.305 0 0 1-.24.107.305.305 0 0 1-.238-.107L3.67 9.462l-.068-.106z"
        />
      </g>
    </Svg>
  );
};

export default CheckSvg;
