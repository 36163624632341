import React from "react";
import styled, { css } from "styled-components";
import get from "lodash/get";
import Link from "next/link";

import ImageItem from "../image-item";
import PosterInformationBar from "./poster-information-bar";
import PosterContent, {
  PosterContentProps,
  PosterContentType,
} from "./poster-content";
import { SizedComponent } from "../silo-components/auto-sizer";

import { useResponsive } from "../responsive-provider";
import { MHMediaObject } from "@/types/api";

const SizingConfig = {
  padding: ({ isTabletLMaxWidth }) => (isTabletLMaxWidth ? 5 : 10),
  minWidth: 160,
};

type ItemProps = {
  $width: number;
  $isTouchDevice: boolean;
};
const Item = styled.div<ItemProps>`
  ${(props) => css`
    box-sizing: border-box;
    color: ${props.theme.Color.PrimaryColor};
    display: inline-block;
    vertical-align: top;
    text-align: left;
    text-decoration: none;
    width: 100%;

    min-width: ${SizingConfig.minWidth}px;
    height: 100%;

    /* These styles ensure that all poster heights of siblings are the same */
    display: flex;
    flex-direction: column;

    ${props.$width &&
    css`
      width: ${props.$width}%;
    `}
  `}
`;

export enum ContentType {
  MOVIE,
  SHOWSERIES,
  CONTRIBUTOR,
}
const getType = (item) => {
  if (!item || !item.mhid) {
    // we only use placeholders for the clusters atm.
    return ContentType.MOVIE;
  }

  switch (item.mhid.slice(0, 5)) {
    case "mhric":
      return ContentType.CONTRIBUTOR;
    case "mhsss":
      return ContentType.SHOWSERIES;
    case "mhmov":
    default:
      return ContentType.MOVIE;
  }
};

const defaultPosterContent = (type) => {
  switch (type) {
    case ContentType.MOVIE:
    case ContentType.SHOWSERIES:
      return ({ isHovered }) => isHovered && PosterContentType.RATING;

    case ContentType.CONTRIBUTOR:
      return () => PosterContentType.STATS;
  }
};

type PosterProps = PosterContentProps & {
  item?: MHMediaObject | { [k: string]: null };
  width?: number;
  getDescription?: (props: {
    item: MHMediaObject | { [k: string]: null };
  }) => string;
  href?: string;
  showInfoBar?: boolean;
};
const Poster: SizedComponent<PosterProps> = ({
  item = {},
  contentIndex,
  onPosterClicked,
  width,
  posterContent: rawPosterContent,
  getDescription,
  href,
  showInfoBar = true,
}) => {
  const { isTouchDevice } = useResponsive();
  const itemContentType = getType(item);

  const posterContent =
    rawPosterContent || defaultPosterContent(itemContentType);

  const poster = (
    <PosterContent
      {...{
        item,
        contentIndex,
        onPosterClicked,
        posterContent,
      }}
    />
  );

  return (
    <Item $isTouchDevice={isTouchDevice} $width={width}>
      {/* if we are passing an empty item then its safe to assume that its a
        placeholder */}
      <ImageItem
        {...{ item }}
        isPlaceholder={!item}
        isPerson={get(item, "mhid", "").slice(0, 5) === "mhric"}
      >
        {href ? (
          <Link {...{ href }}>
            <a>{poster}</a>
          </Link>
        ) : (
          poster
        )}
      </ImageItem>

      {showInfoBar && (
        <PosterInformationBar
          item={item}
          type={itemContentType}
          getDescription={getDescription}
        />
      )}
    </Item>
  );
};

Poster.SizingConfig = SizingConfig;

export default Poster;
