export enum GaugeRatingValue {
  GARBAGE,
  HATED_IT,
  DISLIKED_IT,
  MEH,
  LIKED_IT,
  LOVED_IT,
  ALL_TIME_FAVE,
}

export const GAUGE_RATING_NEGATIVE = new Set([
  GaugeRatingValue.DISLIKED_IT,
  GaugeRatingValue.HATED_IT,
  GaugeRatingValue.GARBAGE,
]);
export const GAUGE_RATING_POSITIVES = new Set([
  GaugeRatingValue.MEH,
  GaugeRatingValue.LIKED_IT,
  GaugeRatingValue.LOVED_IT,
  GaugeRatingValue.ALL_TIME_FAVE,
]);
