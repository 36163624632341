import React from "react";
import styled, { CSSProperties, css, ThemeProvider } from "styled-components";

import ButtonIcon, { ButtonIconProps } from "../controls/button-icon";
import BookmarkSvg from "./icons/icon-bookmark";
import PlaySvg from "./icons/icon-play";
import NotAllowedSvg from "./icons/not-allowed-svg";

type StyledIconProps = {
  size: number;
};
const makeStyledIcon = (rawIcon) => styled(rawIcon)<StyledIconProps>`
  ${(props) => css`
    width: ${props.theme.IconSize}px;
    height: ${props.theme.IconSize}px;
  `}
`;

enum IconType {
  BOOKMARK,
  NOT_ALLOWED,
  PLAY,
}

const StyledBookmarkSvg = makeStyledIcon(BookmarkSvg);
const StyledNotAllowedSvg = makeStyledIcon(NotAllowedSvg);
const StyledPlaySvg = makeStyledIcon(PlaySvg);

const ICON_LOOKUP: { [k in IconType]: any } = {
  [IconType.BOOKMARK]: StyledBookmarkSvg,
  [IconType.NOT_ALLOWED]: StyledNotAllowedSvg,
  [IconType.PLAY]: StyledPlaySvg,
};

type SizedIconProps = {
  size?: number;
  icon: IconType;
  style?: CSSProperties;
  className?: string;
};
const SizedIcon = ({ size, icon, style, className }: SizedIconProps) => {
  const IconComponent = ICON_LOOKUP[icon];

  const sizedIcon = <IconComponent style={style} className={className} />;

  if (size) {
    const sizedIconTheme = (prev) => ({
      ...prev,
      IconSize: size,
    });
    return <ThemeProvider theme={sizedIconTheme}>{sizedIcon}</ThemeProvider>;
  }
  return sizedIcon;
};

type SizedIconButtonProps = Omit<ButtonIconProps, "Icon"> & {
  iconSize?: number;
  icon: IconType;
};
const SizedIconButton = ({ iconSize, icon, ...rest }: SizedIconButtonProps) => {
  const IconComponent = ICON_LOOKUP[icon];
  const button = <ButtonIcon Icon={IconComponent} {...rest} />;

  if (iconSize) {
    const sizedIconTheme = (prev) => ({
      ...prev,
      IconSize: iconSize,
    });
    return <ThemeProvider theme={sizedIconTheme}>{button}</ThemeProvider>;
  }

  return button;
};

SizedIcon.Button = SizedIconButton;
SizedIcon.IconType = IconType;
export default SizedIcon;
