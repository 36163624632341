import React from "react";
import styled, { css } from "styled-components";

import Stack, {
  StackSpacing,
  StackDirection,
  StackAlignment,
  stackSpacingLookup,
} from "../stack";

type ContainerProps = {
  $padding: number;
};
const Container = styled.div<ContainerProps>`
  ${(props) => css`
    /* Each inner item has a padding. But we don't want this WrappedItems component as a whole to dictate the padding. We want parents to dictate the padding. So we remove any outside perimeter padding caused by the individual wrapped items */
    margin: ${props.$padding / -2}px;
  `}
`;

type WrappedItemsProps = {
  horizontalAlignment?: StackAlignment;
  children: JSX.Element[];
};
const WrappedItems = ({
  children,
  horizontalAlignment = StackAlignment.CENTER,
}: WrappedItemsProps) => (
  <Container $padding={stackSpacingLookup[StackSpacing.SMALL]}>
    <Stack
      direction={StackDirection.ROW}
      spacing={StackSpacing.SMALL}
      horizontalAlignment={horizontalAlignment}
      wrapItems
    >
      {children}
    </Stack>
  </Container>
);

export default WrappedItems;
