import React, { useState } from "react";
import styled from "styled-components";
import { animated, useSpring } from "react-spring";

import { useResponsive } from "../responsive-provider";

export const OverlayContainer = styled(animated.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
`;

type ContainerProps = {
  $zIndex?: number;
};
const FullSizeContainer = styled.div<ContainerProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: ${(props) => props.$zIndex || 1};
`;

type HoverAppearContainerProps = {
  children: React.ReactNode;
  zIndex?: number;
};
export const HoverAppearContainer = ({
  children,
  zIndex,
}: HoverAppearContainerProps) => {
  const { isTouchDevice } = useResponsive();

  const [isHovered, setIsHovered] = useState(false);
  const { opacity } = useSpring({
    opacity: isHovered ? 1 : 0,
    config: { mass: 1, tension: 2000, friction: 120, clamp: true },
  });

  return (
    <FullSizeContainer
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      $zIndex={zIndex}
    >
      {!isTouchDevice && (
        <OverlayContainer style={{ opacity } as any}>
          {children}
        </OverlayContainer>
      )}
    </FullSizeContainer>
  );
};

export default HoverAppearContainer;
