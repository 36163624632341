import React, { useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { animated, useSpring } from "react-spring";

import CheckSvg from "../assets/icons/check-svg";
import StatsSvg from "../assets/icons/icon-stats";
import CloseButton from "../controls/close-button";
import NotAllowedSvg from "../assets/icons/not-allowed-svg";

import useTheme from "../../hooks/use-theme";

type IconContainerProps = {
  $hasText?: boolean;
};
const IconContainer = styled(animated.div)<IconContainerProps>`
  ${(props) => css`
    color: ${props.theme.Color.UnchangeableIconColor};

    /* Necessary for places like the stats posters, that show both an svg and text as the icon */
    display: flex;
    flex-direction: column;
    align-items: center;

    & > svg {
      margin-bottom: ${props.$hasText ? props.theme.Padding : 0}px;
      width: 60px;
    }
  `}
`;

const BackgroundContainer = styled.div`
  background: ${(props) => props.theme.Color.PosterIconBackgroundColor};
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export enum IconType {
  CHECK,
  STATS,
  UNAVAILABLE,
  REMOVED,
}
type IconProps = {
  type: IconType;
  onClick?: any;
};
export const PosterIcon = ({ type, onClick }: IconProps) => {
  const theme = useTheme();

  const [isHovered, setIsHovered] = useState(false);
  const { scale } = useSpring({
    scale: isHovered ? 1 : 0.8,
    config: { mass: 5, tension: 1200, friction: 80 },
  });

  const svg = useMemo(() => {
    switch (type) {
      case IconType.UNAVAILABLE:
        return <NotAllowedSvg />;
      case IconType.REMOVED:
        return (
          <CloseButton
            onClick={() => {}}
            color={theme.Color.PrimaryColor}
            size={50}
          />
        );
      case IconType.CHECK:
        return <CheckSvg />;
      case IconType.STATS:
        return (
          <>
            <StatsSvg />
            See More
          </>
        );
    }
  }, [type, theme]);
  return (
    <BackgroundContainer
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick ? onClick : () => {}}
    >
      <IconContainer
        style={{ transform: scale.to((s) => `scale(${s})`) }}
        $hasText={type === IconType.STATS}
      >
        {svg}
      </IconContainer>
    </BackgroundContainer>
  );
};
export default PosterIcon;
