import React from "react";
import Svg from "../svg";

type IconProps = {
  className?: string;
};

const PlaySvg = ({ className }: IconProps) => (
  <Svg width="8" height="12" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.188 5.725l-7.334-5A.334.334 0 0 0 .334 1v10a.333.333 0 0 0 .52.275l7.334-5a.333.333 0 0 0 0-.55z"
    />
  </Svg>
);

export default PlaySvg;
