import React from "react";
import Svg from "../svg";

type IconProps = {
  className?: string;
};

const BookmarkSvg = ({ className }: IconProps) => (
  <Svg
    width="8"
    height="10"
    className={className}
    preserveAspectRatio="xMidYMid meet"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.333.33A.643.643 0 0 0 7.05.06.5.5 0 0 0 6.814 0H1.186a.5.5 0 0 0-.237.06.643.643 0 0 0-.282.27.818.818 0 0 0-.105.41V10c0 .1 1.146-.907 3.438-3.02C6.292 9.092 7.438 10.1 7.438 10V.74a.818.818 0 0 0-.105-.41z"
    />
  </Svg>
);

export default BookmarkSvg;
