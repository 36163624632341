type MHImageFormat = {
  height: number;
  url: string;
  width: number;
  isDefault?: boolean;
};

export type MHImage = {
  averageColor?: any;
  context?: any;
  isDefault: boolean;
  large?: MHImageFormat;
  medium?: MHImageFormat;
  mhid: string;
  name?: string;
  original?: MHImageFormat;
  small?: MHImageFormat;
  thumbnail?: MHImageFormat;
};

type MHContext = {
  createdAt?: string;
  metadata?: any;
  relationships?: any;
  count?: number;
};

type MHSource = {
  altId: string;
  context: any;
  mhid: string;
  name: string;
};

export type MHMediaObject = {
  altId: string;
  context?: MHContext;
  description: string;
  duration?: number;
  mhid: string;
  name: string;
  primaryImage?: MHImage;
  releaseDate: number;
  secondaryImage?: MHImage;
  sources: MHSource[];
};

export type MHPagingInfo = {
  next: any; //TODO:
};

type Factor = {
  id: string;
  name?: string;
  weight?: number;
};

export enum ReturnType {
  MOVIE = "movie",
  SHOWSERIES = "showseries",
  CONTRIBUTOR = "contributor",
  TRAIT = "trait",
}

export enum LogicOperator {
  AND = "$and",
  OR = "$or",
  NOT = "$not",
  EQ = "$eq",
  NE = "$ne",
  LT = "$lt",
  LTE = "$lte",
  GT = "$gt",
  GTE = "$gte",
  ALL = "$all",
  IN = "$in",
}

export enum FilterType {
  TRAITS = "traits",
  YEAR = "year",
  SOURCES = "sources",
  SOURCES_OF = "sourcesOf",
  EXCLUDE = "exclude",
  SUITABILITIES = "suitabilities",
  RATING = "rating",
  RESTRICT_TO = "restrictTo",
  RETURN_TYPE = "returnType",
}

export enum UserActionType {
  SEEN = "SEEN",
  SAVED = "SAVED",
  RATE = "RATE",
}

type LogicStatement<T> = {
  [LogicOperator.AND]?: LogicStatement<T>[];
  [LogicOperator.OR]?: LogicStatement<T>[];
  [LogicOperator.ALL]?: T[];
  [LogicOperator.IN]?: T[];
  [LogicOperator.NOT]?: LogicStatement<T>;
  [LogicOperator.EQ]?: T;
  [LogicOperator.NE]?: T;
  [LogicOperator.LT]?: T;
  [LogicOperator.LTE]?: T;
  [LogicOperator.GT]?: T;
  [LogicOperator.GTE]?: T;
};

export type Filter = {
  [FilterType.YEAR]?: LogicStatement<number>;
  [FilterType.TRAITS]?: LogicStatement<string>;
  [FilterType.SOURCES]?: LogicStatement<string>;
  [FilterType.SOURCES_OF]?: LogicStatement<string>;
  [FilterType.EXCLUDE]?: LogicStatement<string>;
  [FilterType.SUITABILITIES]?: LogicStatement<string>;
  [FilterType.RATING]?: LogicStatement<number>;
  [FilterType.RESTRICT_TO]?: LogicStatement<string>;
  [FilterType.RETURN_TYPE]?: LogicStatement<ReturnType>;
};

enum PenaltyMode {
  any = "any",
  all = "all",
}

type PenaltyConfiguration = {
  scoreModifier: number;
  operator?: PenaltyMode;
  stopValue?: number;
};

type Penalty = {
  suitabilities?: PenaltyConfiguration;
  iconicTraits?: PenaltyConfiguration;
  popularity?: PenaltyConfiguration;
  quality?: PenaltyConfiguration;
  recency?: PenaltyConfiguration;
  rating?: PenaltyConfiguration;
};

type Bias = {
  operator: string;
  scoreModifier: number;
  params?: object;
};

type BiasConfig = {
  max?: number;
};

type FeatureFlags = {
  addTraitTierFactors?: boolean;
  addDefaultBias?: boolean;
  addDefaultPenalties?: boolean;
  includeDiagnostics?: boolean;
  hierarchicalTiers?: boolean;

  [k: string]: boolean;
};

export enum TraitModifierOperator {
  tier = "tier",
  tierBeta = "tierBeta",
}

type TraitModifierParams = {
  tier: number;
};

type TraitModifier = {
  operator?: TraitModifierOperator;
  params?: TraitModifierParams;
  scoreModifier?: number;
};

export type Component = {
  name: string;
  components?: Component[];
  ref?: string;
  bypassCache?: boolean;
  values?: string[];
  depth?: number;
  pageSize?: number;
  filters?: Filter;
};

export type RelateParams = {
  factors: Factor[];
  filters: Filter;
  components?: Component[];
  traitModifiers?: TraitModifier[];
  penalties?: Penalty;
  bias?: Bias[];
  userId?: string;
  page?: number;
  pageSize?: number;
  features?: FeatureFlags;
  biasConfig?: BiasConfig;
};
type ClientApiResponse = {
  payload: any;
  isChangingRoute: boolean;
};

export type ClientApiFn = (
  endpoint: string,
  params: object
) => Promise<ClientApiResponse>;
