import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import ReactDOM from "react-dom";
import { mix } from "polished";
import styled from "styled-components";
import { GaugeRatingValue } from "../../@types/rating";
import { ArcSeries, XYPlot } from "react-vis";
import { animated } from "react-spring";

import useTheme from "../../hooks/use-theme";

import IconQuestionMarkSvg from "../assets/icons/icon-question-mark";

type TextProps = {
  $disabled?: boolean;
};
const Text = styled(animated.div)<TextProps>`
  cursor: ${(props) => (props.$disabled ? "default" : "pointer")} !important;
  font-size: 16px;
  text-align: center;
  user-select: none;

  position: absolute;
  top: ${(props) => props.theme.Padding * 5}px;
  left: 50%;
  transform: translate(-50%, -25%);
  z-index: 1;
  white-space: normal;
`;

const StyledQuestionMark = styled.div`
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -10px);
  z-index: 2;
`;

const Plot = styled(XYPlot)`
  cursor: ${(props) => (props.disabled ? "default" : "pointer")} !important;
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: ${(props) => props.height / 1.5}px !important;
`;

type ContainerProps = {
  $size: number;
};
const Container = styled.div<ContainerProps>`
  position: relative;
  width: ${(props) => props.$size}px;
  height: ${(props) => (props.$size * 2) / 3}px;
`;

const ratingValueToLabel: { [key in GaugeRatingValue]: string } = {
  [GaugeRatingValue.GARBAGE]: "Garbage",
  [GaugeRatingValue.HATED_IT]: "Hated It",
  [GaugeRatingValue.DISLIKED_IT]: "Disliked It",
  [GaugeRatingValue.MEH]: "Meh",
  [GaugeRatingValue.LIKED_IT]: "Liked It",
  [GaugeRatingValue.LOVED_IT]: "Loved It",
  [GaugeRatingValue.ALL_TIME_FAVE]: "All Time Fave",
};

const sectors = [
  { size: 1, value: GaugeRatingValue.GARBAGE },
  { size: 2, value: GaugeRatingValue.HATED_IT },
  { size: 2, value: GaugeRatingValue.DISLIKED_IT },
  { size: 2, value: GaugeRatingValue.MEH },
  { size: 2, value: GaugeRatingValue.LIKED_IT },
  { size: 2, value: GaugeRatingValue.LOVED_IT },
  { size: 1, value: GaugeRatingValue.ALL_TIME_FAVE },
];

const totalSectorSize = sectors.reduce((a, { size }) => a + size, 0);

const noop = () => {};

const legacyGaugeOffsetAngle = -90;
export const MAX_GAUGE_VALUE = 310;
export const TOTAL_GAUGE_VALUE = MAX_GAUGE_VALUE + legacyGaugeOffsetAngle;
export const rawValueToRating = (rawValue) => {
  return (10 * (rawValue + legacyGaugeOffsetAngle)) / TOTAL_GAUGE_VALUE;
};
export const ratingToRawValue = (rating) => {
  return (rating / 10) * TOTAL_GAUGE_VALUE - legacyGaugeOffsetAngle;
};
type GaugeProps = {
  startAngle?: number;
  endAngle?: number;
  disabled?: boolean;
  size?: number;
  noText?: boolean;
  initialRawValue?: number;
  thickness?: number;
  onSelect?: any;
  showQuestionMark?: boolean;
};
const Gauge = ({
  startAngle = -110,
  endAngle = 110,
  disabled = false,
  noText = false,
  initialRawValue = -1 - legacyGaugeOffsetAngle,
  size = 120,
  thickness = 0.2,
  onSelect,
  showQuestionMark = false,
}: GaugeProps) => {
  const ref = useRef();
  const [rawValue, setRawValue] = useState(initialRawValue);

  useEffect(() => {
    setRawValue(initialRawValue);
  }, [initialRawValue]);

  const initialRadian = useMemo(() => {
    const value = initialRawValue + legacyGaugeOffsetAngle;
    return (value * Math.PI) / 180;
  }, [initialRawValue]);
  const renderedRadian = useMemo(() => {
    const value = rawValue + legacyGaugeOffsetAngle;
    return (value * Math.PI) / 180;
  }, [rawValue]);

  const startRadian = (Math.PI * startAngle) / 180;
  const endRadian = (Math.PI * endAngle) / 180;
  const totalRadianSize = endRadian - startRadian;
  const unitRadians = totalRadianSize / totalSectorSize;

  const theme = useTheme();
  const getColor = (radian) =>
    mix(radian / totalRadianSize, theme.Color.Green, theme.Color.Yellow);

  const ratingValue = useMemo(() => {
    const currentSector = sectors.reduce((acc, sector, i) => {
      const sectionStart =
        sectors.slice(0, i).reduce((a, { size }) => a + size, 0) * unitRadians;
      if (renderedRadian < sectionStart) {
        return acc;
      } else {
        return sector;
      }
    }, null);

    return currentSector && currentSector.value;
  }, [renderedRadian, unitRadians]);

  const activeColor = getColor(renderedRadian);

  const resetGauge = () => {
    setRawValue(initialRawValue);
  };
  const onChange = () => {
    onSelect({
      raw: Math.floor(rawValue),
      rating: ratingValue,
    });
  };
  const onMouseMove = useCallback(
    (e) => {
      const boundingContainer = ReactDOM.findDOMNode(ref.current);
      const boundingRect = boundingContainer
        ? boundingContainer.getBoundingClientRect()
        : {
            width: 0,
            height: 0,
            left: 0,
            top: 0,
          };
      const xAdjustment = boundingRect.width / 2;
      const yAdjustment = boundingRect.height - xAdjustment;
      const relativeClientX = e.clientX - boundingRect.left - xAdjustment;
      const relativeClientY =
        boundingRect.height - (e.clientY - boundingRect.top) - yAdjustment;

      let angle =
        180 - (Math.atan2(relativeClientY, relativeClientX) * 180) / Math.PI;
      // We add a little so that the negative value doesn't show up as an almost full circle
      const angleWithOffset = angle + legacyGaugeOffsetAngle;

      if (angleWithOffset - 360 > startAngle) {
        angle = angle - 360;
        // We clip the angle to (startAngle, endAngle)
      } else if (angleWithOffset > endAngle) {
        if (angleWithOffset % 180 < 90) {
          angle = startAngle - legacyGaugeOffsetAngle;
        } else {
          angle = endAngle - legacyGaugeOffsetAngle;
        }
      }
      setRawValue(angle - startAngle);
    },
    [startAngle, endAngle]
  );

  const outerDonutSize = 1 - thickness;
  const innerDonutSize = 1 - 1.25 * thickness; // inner circle should be a little thinner
  const outerRadius = size / 2;
  const innerRadius = outerRadius * outerDonutSize;
  const innerCircleInnerRadius = innerRadius * innerDonutSize;

  const data = [
    {
      name: "rating",
      angle0: startRadian,
      angle: Math.max(startRadian, startRadian + renderedRadian),
      radius0: innerRadius,
      radius: outerRadius,
      color: activeColor,
    },
    {
      name: "blank",
      angle0: startRadian + renderedRadian,
      angle: endRadian,
      radius0: innerRadius,
      radius: outerRadius,
      color: theme.Color.GaugeColor,
    },
  ];
  const legendData = sectors.map(({ size }, i) => {
    const sectionStart =
      sectors.slice(0, i).reduce((a, { size }) => a + size, 0) * unitRadians;
    return {
      name: `${i}`,
      angle0: startRadian + sectionStart,
      angle: startRadian + sectionStart + size * unitRadians,
      color:
        renderedRadian >= sectionStart ? activeColor : theme.Color.GaugeColor,
      radius0: innerCircleInnerRadius,
      radius: outerRadius * innerDonutSize,
    };
  });

  const arcSeriesNeededProps = {
    //no idea why these numbers work
    marginLeft: 25,
    marginTop: 25,
    radiusType: "literal",
    center: { x: 0, y: 0 },
    colorType: "literal",
    stroke: "transparent",
  };
  return (
    <Container $size={size}>
      {showQuestionMark && (
        <StyledQuestionMark>
          <IconQuestionMarkSvg />
        </StyledQuestionMark>
      )}
      <Plot
        xDomain={[-1, 1]}
        yDomain={[-1, 1]}
        width={size}
        height={size}
        ref={ref}
        onMouseMove={disabled ? noop : onMouseMove}
        onMouseLeave={disabled ? noop : resetGauge}
        onClick={disabled ? noop : onChange}
        disabled={disabled}
      >
        {/* legend */}
        <ArcSeries
          {...arcSeriesNeededProps}
          data={legendData}
          padAngle={0.06}
        />
        {/* raw hovered value */}
        <ArcSeries {...arcSeriesNeededProps} data={data} />
        {/* selected value */}
        {initialRadian > 0.06 && (
          <ArcSeries
            {...arcSeriesNeededProps}
            data={[
              {
                angle0: startRadian + initialRadian - 0.06,
                angle: startRadian + initialRadian,
                color: getColor(initialRadian),
                radius0: innerRadius,
                radius: outerRadius,
              },
            ]}
          />
        )}
      </Plot>
      {!noText && ratingValueToLabel[ratingValue] && (
        <Text $disabled={disabled}>{ratingValueToLabel[ratingValue]}</Text>
      )}
    </Container>
  );
};

Gauge.Text = Text;

export default Gauge;
