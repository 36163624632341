import React, { useState, useRef, useMemo } from "react";
import styled from "styled-components";
import { animated, useTransition } from "react-spring";

import useOnMenuClose from "../../hooks/use-on-menu-close";

import PosterIcon, { IconType } from "./poster-icon";
import PosterButton from "./poster-add-back-button";
import MediaPosterActions from "./media-poster-actions";

import { useResponsive } from "../responsive-provider";
import { MHMediaObject } from "../../@types/api";

type ContainerProps = {
  $showCursor?: boolean;
  $disableClicking?: boolean;
};
const PosterContentContainer = styled(animated.div)<ContainerProps>`
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;

  @media (hover: hover) {
    color: ${(props) => props.theme.Color.PrimaryColor};
    &:hover {
      color: ${(props) => props.theme.Color.PrimaryColor};
    }
  }

  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  ${(props) => props.$showCursor && `cursor: pointer;`}
  & div, & a {
    ${(props) => props.$disableClicking && `pointer-events: none;`}
  }
`;

const OpacityContainer = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const CursorContainer = styled.div`
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
`;

export enum PosterContentType {
  BLANK,
  CURSOR,
  RATING,
  CHECK,
  STATS,
  UNAVAILABLE,
  REMOVED,
  ADD_BACK_BUTTON,
}

export type PosterContentParams = {
  item: MHMediaObject | { [k: string]: null };
  isHovered: boolean;
};
export type PosterContentProps = {
  item: MHMediaObject | { [k: string]: null };
  contentIndex: number;
  onPosterClicked?: (props: {
    item: MHMediaObject | { [k: string]: null };
  }) => void;
  posterContent?: (PosterContentParams) => PosterContentType;
};
const PosterContent = ({
  item,
  contentIndex,
  onPosterClicked,
  posterContent,
}: PosterContentProps) => {
  const posterRef = useRef();
  const { isTouchDevice } = useResponsive();
  const [isHovered, setIsHovered] = useState(false);
  useOnMenuClose({
    ref: posterRef,
    handler: () => isTouchDevice && setIsHovered(false),
    closeOnMove: false,
  });

  const content = useMemo(() => posterContent({ item, isHovered }), [
    item,
    isHovered,
    posterContent,
  ]);

  const Component = useMemo(() => {
    switch (content) {
      case PosterContentType.CHECK:
        return (
          <PosterIcon
            type={IconType.CHECK}
            onClick={() => onPosterClicked && onPosterClicked({ item })}
          />
        );
      case PosterContentType.STATS:
        return <PosterIcon type={IconType.STATS} />;
      case PosterContentType.RATING:
        return (
          <MediaPosterActions {...{ item, contentIndex, onPosterClicked }} />
        );
      case PosterContentType.CURSOR:
        return (
          <CursorContainer
            onClick={() => onPosterClicked && onPosterClicked({ item })}
          />
        );
      case PosterContentType.UNAVAILABLE:
        return <PosterIcon type={IconType.UNAVAILABLE} />;
      case PosterContentType.REMOVED:
        return <PosterIcon type={IconType.REMOVED} />;
      case PosterContentType.ADD_BACK_BUTTON:
        return <PosterButton onClick={() => onPosterClicked({ item })} />;
      case PosterContentType.BLANK:
      default:
        return <div />;
    }
  }, [content, contentIndex, item, onPosterClicked]);

  const transitionContent = useTransition(Component, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { mass: 1, tension: 2000, friction: 100 },
  });

  return (
    <PosterContentContainer
      ref={posterRef}
      onClick={() => {
        if (isTouchDevice) {
          setIsHovered(true);
        }
      }}
      onMouseEnter={() => !isTouchDevice && setIsHovered(true)}
      onMouseLeave={() => !isTouchDevice && setIsHovered(false)}
      $showCursor={[PosterContentType.STATS, PosterContentType.CHECK].includes(
        content
      )}
      $disableClicking={!isHovered}
    >
      {transitionContent((props, item) => (
        <OpacityContainer style={props as any}>{item}</OpacityContainer>
      ))}
    </PosterContentContainer>
  );
};

export default PosterContent;
