import useUserActionState from "@/hooks/use-user-action-state";
import useUpdateUserAction from "./use-update-user-action";

const useUserActionUpdateUninterested = () => {
  const {
    uninterestedMhids,
    setUninterestedMhids,
    setUninterestedFulfilled,
  } = useUserActionState();

  const { toggleMedia } = useUpdateUserAction({
    mhids: uninterestedMhids,
    setMhids: setUninterestedMhids,
    setFulfilled: setUninterestedFulfilled,
    apiRoute: "uninterested",
  });
  return { toggleUninterested: toggleMedia };
};

export default useUserActionUpdateUninterested;
