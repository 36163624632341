import React, { useCallback, useMemo } from "react";
import styled from "styled-components";

import { useResponsive } from "../responsive-provider";
import useWindowSize from "../../hooks/use-window-size";
import useBreakpoints, {
  BoundingBoxSize,
  Breakpoint,
} from "../../hooks/use-breakpoints";
import { RequireAtLeastOne } from "@/util/typescript";

type ContainerProps = {
  padding: number;
  $isTouchDevice: boolean;
};
const Container = styled.div<ContainerProps>`
  margin: 0;
  width: 100%;
  height: 100%;
  flex: 1;
`;

type SizingFunctionParams = {
  isTabletLMaxWidth: boolean;
  windowHeight: number;
  windowWidth: number;
};
export type ChildrenProps = Partial<BoundingBoxSize> & {
  breakpoint: Breakpoint;
  inContainer?: boolean;
  padding?: number;
  children: React.ReactNode;
  minWidth?: number;
  maxWidth?: number;
};
export type SizingConfig = RequireAtLeastOne<
  {
    minWidth: number | ((props: SizingFunctionParams) => number);
    maxWidth: number | ((props: SizingFunctionParams) => number);
    padding: number | ((props: SizingFunctionParams) => number);
  },
  "minWidth" | "maxWidth"
>;
export type AutoSizerProps = {
  children?: (ChildrenProps) => React.ReactNode;
  sizingConfig: SizingConfig;
  inContainer?: boolean;
};
const AutoSizer = ({ children, sizingConfig }: AutoSizerProps) => {
  const { isTouchDevice } = useResponsive();

  const {
    isTabletL,
    height: windowHeight,
    width: windowWidth,
  } = useWindowSize();

  const getValue: (number) => number = useCallback(
    (raw) => {
      const sizingFunctionParams = {
        isTabletLMaxWidth: isTabletL.MAX,
        windowHeight,
        windowWidth,
      };
      if (Number.isFinite(raw)) {
        return raw;
      } else if (typeof raw === "function") {
        return raw(sizingFunctionParams);
      }
    },
    [isTabletL, windowHeight, windowWidth]
  );

  const { padding, minWidth, maxWidth } = useMemo(() => {
    const {
      minWidth: rawMinWidth,
      padding: rawPadding,
      maxWidth: rawMaxWidth,
    } = sizingConfig;
    return {
      padding: getValue(rawPadding),
      minWidth: getValue(rawMinWidth),
      maxWidth: getValue(rawMaxWidth),
    };
  }, [getValue, sizingConfig]);

  const [breakpoint, sizing, ref] = useBreakpoints({
    minWidth,
    maxWidth,
    padding,
  });

  return (
    <Container ref={ref} padding={padding} $isTouchDevice={isTouchDevice}>
      {children({ breakpoint, padding, minWidth, maxWidth, ...sizing })}
    </Container>
  );
};

export const WithAutoSizer = <P extends object>(
  Component: React.ComponentType<P>
) => {
  const AutoSizedComponent = ({
    sizingConfig,
    ...props
  }: Omit<AutoSizerProps, "children"> &
    Omit<P, keyof ChildrenProps> & { children: React.ReactNode }) => (
    <AutoSizer {...{ sizingConfig }}>
      {(autoSizerData) => <Component {...autoSizerData} {...props} />}
    </AutoSizer>
  );

  return AutoSizedComponent;
};

export type SizedComponent<P> = React.FC<P> & {
  SizingConfig: SizingConfig;
};

export default AutoSizer;
