import { ReturnType } from "@/types/api";

export enum MediaType {
  MOVIE,
  SHOWSERIES,
}

type MediaTypeInfo = {
  displayName: string;
  returnType: ReturnType;
  contentType: MediaType;
  path: string;
  prefix: string;
};
export const contentTypeInfo: MediaTypeInfo[] = [
  {
    displayName: "Movies",
    returnType: ReturnType.MOVIE,
    contentType: MediaType.MOVIE,
    path: "movie",
    prefix: "mhmov",
  },
  {
    displayName: "TV Shows",
    returnType: ReturnType.SHOWSERIES,
    contentType: MediaType.SHOWSERIES,
    path: "tv",
    prefix: "mhsss",
  },
];

export const getPath = (mhid) => {
  const info = contentTypeInfo.find(
    ({ prefix }) => mhid.slice(0, 5) === prefix
  );
  return info.path;
};
