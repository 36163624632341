import React from "react";
import styled from "styled-components";

import Button from "../controls/button";

const BackgroundContainer = styled.div`
  background: ${(props) => props.theme.Color.PosterIconBackgroundColor};
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

type IconProps = {
  onClick: any;
};
export const PosterButton = ({ onClick }: IconProps) => {
  return (
    <BackgroundContainer>
      <Button cta onClick={onClick}>
        Add Back
      </Button>
    </BackgroundContainer>
  );
};
export default PosterButton;
