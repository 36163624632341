import React from "react";

import Svg from "../svg";
type IconProps = {
  className?: string;
};
const NotAllowedSvg = ({ className }: IconProps) => (
  <Svg
    width="50"
    height="50"
    preserveAspectRatio="xMidYMid meet"
    className={className}
  >
    <path
      d="M25 0C11.215 0 0 11.215 0 25s11.215 25 25 25 25-11.215 25-25S38.785 0 25 0zm13.059 41.409C34.471 44.273 29.938 46 25 46 13.421 46 4 36.579 4 25c0-4.703 1.555-9.05 4.177-12.554.33-.442.939-.475 1.335-.09L38.107 40.07c.397.385.381.994-.048 1.339zm4.105-4.357c-.318.451-.917.492-1.312.107L12.338 9.524c-.396-.385-.374-.984.067-1.316A20.884 20.884 0 0 1 25 4c11.579 0 21 9.421 21 21 0 4.486-1.428 8.639-3.836 12.052z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Svg>
);

export default NotAllowedSvg;
