import { useCallback } from "react";

import { checkHasMedia } from "@/util/check-user-action-data";
import useCarouselMetadata from "@/hooks/use-carousel-metadata";

import { useRouter } from "next/router";
import useClientApi from "@/hooks/use-client-api";

type UpdateUserActionTypes = {
  mhids: Set<String>;
  setMhids: any;
  setFulfilled?: any;
  apiRoute: string;
  ratedDict?: any;
  setRatedDict?: any;
};

const useUpdateUserAction = ({
  mhids,
  setMhids,
  setFulfilled,
  ratedDict,
  setRatedDict,
  apiRoute,
}: UpdateUserActionTypes) => {
  const router = useRouter();
  const clientApi = useClientApi();
  const siloData = useCarouselMetadata();

  const toggleMedia = useCallback(
    async ({ item, action, ratingValues }) => {
      const { mhid } = item;
      const hasMedia = checkHasMedia({ mhidsArray: mhids, mhid });

      // Action is only passed in to toggle ratings, so only remove if hasMedia is true for seen and saved
      if ((!action && hasMedia) || (action && action === "remove")) {
        try {
          // Optimistic update
          if (!ratedDict) {
            setMhids((prev) => {
              const newSet = new Set(prev);
              newSet.delete(mhid);
              return newSet;
            });
            setFulfilled &&
              setFulfilled((prev) => prev.filter((m) => mhid !== m.mhid));
          } else {
            const { [mhid]: rm, ...removedMhidDict } = ratedDict;
            setRatedDict(removedMhidDict);
          }

          await clientApi(`/api/profile/action/undo-${apiRoute}`, {
            method: "POST",
            data: {
              contentMhid: mhid,
            },
          });
        } catch (err) {
          // TODO: handle the failure case
          console.log(`Undo ${apiRoute} error`, err);
        }
      } else {
        try {
          // Optimistic update
          // In the case of adding ratings to existing seen media, do not update state again
          if (!hasMedia) {
            setMhids((prev) => {
              const newSet = new Set(prev);
              newSet.add(mhid);
              return newSet;
            });
            setFulfilled && setFulfilled((prev) => [item, ...prev]);
          }
          if (ratingValues) {
            setRatedDict((prevState) => ({
              ...prevState,
              [mhid]: {
                raw: ratingValues.rawValue,
                rating: ratingValues.rating,
              },
            }));
          }
          await clientApi(`/api/profile/action/${apiRoute}`, {
            method: "POST",
            data: {
              contentMhid: mhid,
              originatingPage: router.route,
              siloData,
              ...ratingValues,
            },
          });
        } catch (err) {
          // TODO: handle the failure case
          console.log(`${apiRoute} error`, err);
        }
      }
    },
    [
      setMhids,
      setFulfilled,
      clientApi,
      apiRoute,
      router,
      mhids,
      ratedDict,
      setRatedDict,
      siloData,
    ]
  );

  return { toggleMedia };
};

export default useUpdateUserAction;
