import React from "react";
import Svg from "../svg";

type IconProps = {
  className?: string;
};
const IconQuestionMark = ({ className }: IconProps) => (
  <Svg width="12" height="18" className={className}>
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M6.016 0C3.294 0 1.531 1.102.147 3.067a.803.803 0 0 0 .177 1.107l1.47 1.102a.825.825 0 0 0 1.135-.14C3.783 4.08 4.416 3.472 5.75 3.472c1.049 0 2.346.667 2.346 1.672 0 .76-.634 1.15-1.67 1.724-1.208.669-2.807 1.501-2.807 3.584v.33c0 .447.367.809.819.809H6.91a.814.814 0 0 0 .818-.809v-.194C7.728 9.143 12 9.083 12 5.175 12 2.232 8.91 0 6.016 0zm-.342 12.582c-1.302 0-2.362 1.047-2.362 2.334 0 1.287 1.06 2.334 2.362 2.334 1.303 0 2.362-1.047 2.362-2.334 0-1.287-1.06-2.334-2.362-2.334z"
    />
  </Svg>
);

export default IconQuestionMark;
