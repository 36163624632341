import { useEffect } from "react";

type MenuProps = {
  ref: any;
  handler: any;
  closeOnMove?: boolean;
};
const useOnMenuClose = ({ ref, handler, closeOnMove }: MenuProps) => {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      const handleEscapeKey = (event) => {
        event = event || window.event;
        if (event.keyCode === 27) {
          listener(event);
        }
      };

      const doNothing = () => {};

      document.addEventListener("mousedown", listener);
      document.addEventListener(
        "mousemove",
        closeOnMove ? listener : doNothing
      );
      document.addEventListener("touchstart", listener);
      document.addEventListener("keydown", handleEscapeKey);

      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener(
          "mousemove",
          closeOnMove ? listener : doNothing
        );
        document.removeEventListener("touchstart", listener);
        document.removeEventListener("keydown", handleEscapeKey);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new
    // function on every render that will cause this effect
    // callback/cleanup to run every render. It's not a big deal
    // but to optimize you can wrap handler in useCallback
    // before passing it into this hook.
    [ref, handler, closeOnMove]
  );
};

export default useOnMenuClose;
