import React from "react";
import Svg from "./svg";

type IconProps = {
  className?: string;
};
const ArrowRight = ({ className }: IconProps) => (
  <Svg
    width="13"
    height="20"
    className={className}
    preserveAspectRatio="xMidYMid meet"
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M8.959 10L.222 2.124A.606.606 0 0 1 0 1.664c0-.174.074-.328.222-.461L1.334.2A.742.742 0 0 1 1.844 0c.193 0 .364.067.512.2l10.36 9.34a.606.606 0 0 1 .222.46.606.606 0 0 1-.222.46L2.356 19.8a.742.742 0 0 1-.511.2.74.74 0 0 1-.511-.2L.222 18.797A.606.606 0 0 1 0 18.337c0-.174.074-.327.222-.46L8.96 10z"
    />
  </Svg>
);

export default ArrowRight;
