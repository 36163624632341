import React, { useMemo, useState, createContext } from "react";
import styled, { css } from "styled-components";
import Slider from "react-slick";
import { animated, useSpring } from "react-spring";

import ArrowRight from "../assets/arrow-right";
import { useResponsive } from "../responsive-provider";
import { ChildrenProps, WithAutoSizer } from "./auto-sizer";

const ArrowSvg = styled(ArrowRight)`
  width: 13px;
`;

type CarouselContextProps = {
  type?: string;
  index?: number;
  title?: string;
};

export const CarouselContext = createContext<CarouselContextProps>({
  type: null,
  index: null,
  title: null,
});

const ARROW_WIDTH = 20;
export const BUTTON_SPACING = 30;

const ArrowButton = styled(animated.div).withConfig({
  shouldForwardProp: (prop) => !["currentSlide", "slideCount"].includes(prop),
})`
  ${(props) => props.theme.AppearanceNone()}

  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: ${(props) => props.theme.Color.BorderColor};
  cursor: pointer;
  font-size: 0;
  line-height: 0 ${"" /*  hide default font */};
  outline: none;
  padding: 0;
  position: absolute;
  transform: translate(30px, -50%);
  transition: transform 150ms ease-in-out, color 150ms ease-in-out;
  top: 50%;
  width: ${ARROW_WIDTH}px;

  &.slick-disabled {
    display: none !important;
  }

  @media (hover: hover) {
    &:hover {
      color: ${(props) => props.theme.Color.PrimaryColor};
    }
  }
`;

const PrevArrow = styled(ArrowButton)`
  transform: translate(-30px, -50%) scale(1) rotate(180deg);

  @media (hover: hover) {
    &:hover {
      transform: translate(-30px, -50%) scale(1.2) rotate(180deg);
    }
  }
`;

const NextArrow = styled(ArrowButton)`
  right: 0;
  transform: translateY(30px, -50%) scale(1);

  @media (hover: hover) {
    &:hover {
      transform: translateY(30px, -50%) scale(1.2);
    }
  }
`;

type SliderProps = {
  $stretch: boolean;
  $hideArrows: boolean;
  $slidePadding: number;
};
const SliderStyled = styled(Slider)<SliderProps>`
  position: relative;
  transform: translateX(${(props) => -1 * props.$slidePadding}px);
  width: Calc(100% + ${(props) => 2 * props.$slidePadding}px);

  .slick-list {
    margin: 0 auto;
    overflow: hidden;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    flex: 1;

    /* So we can stretch the heights of the items to be the same height */
    & > div {
      padding: 0 ${(props) => props.$slidePadding}px;
      height: 100%;
    }
  }

  ${(props) =>
    props.$hideArrows &&
    css`
      & ${ArrowButton} {
        display: none !important;
      }
    `}
`;

type TouchDivProps = {
  $slidePadding: number;
  $slideCount: number;
  $inContainer: boolean;
  $minWidth: number;
};
const TouchDiv = styled.div<TouchDivProps>`
  ${(props) => css`
    display: inline-block;

    /* The general rule here is no slider should have top, bottom or side padding. The exception is that touch-device sliders that are in a container do need the $slidePadding on the left and right */
    padding: 0 ${props.$slidePadding}px;

    vertical-align: top;
    width: ${80 / props.$slideCount}%;

    /* Add min width so that the images do not shrink below what we need, i.e., the hover items still need to display correctly on touch */
    min-width: ${props.$minWidth}px;

    /* So we can stretch the heights of the items to be the same height */
    height: 100%;

    ${!props.$inContainer &&
    css`
      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-left: 0;
      }
    `}
  `}
`;

type TouchContainerProps = {
  $slidePadding: number;
  $inContainer?: boolean;
};
const TouchContainer = styled.div<TouchContainerProps>`
  overflow-x: scroll;
  overflow-y: hidden;

  /* Fixes an issue on mobile safari that does not render items out of viewport
     https://stackoverflow.com/questions/58404417/mobile-safari-not-rendering-fixed-element-outside-of-viewport-unless-it-has-no-c */
  transform: translate3d(0, 0, 0);

  ${(props) =>
    !props.$inContainer &&
    css`
      width: Calc(100% + ${props.theme.MobilePagePadding}px);
    `}
`;

const TouchNoWrap = styled.div`
  white-space: nowrap;
`;

//TODO: what to do with empty text?
export type CarouselProps = ChildrenProps & {
  className?: string;
  onScrolled?: (any) => void;
  siloData?: any;
};
const Carousel = ({
  breakpoint,
  inContainer,
  children,
  className,
  onScrolled,
  padding,
  //TODO: this should be in Autosizer and passed to grid too
  siloData,
  minWidth,
}: CarouselProps) => {
  const { isTouchDevice } = useResponsive();

  const [hasMoved, setHasMoved] = useState(false);

  const infinite = useMemo(
    () => hasMoved && breakpoint.count < React.Children.count(children),
    [children, hasMoved, breakpoint]
  );

  const fadeIn = useSpring({
    from: { opacity: 0 },
    opacity: 1,
  }) as any;

  let displayComponent;

  if (isTouchDevice) {
    displayComponent = (
      <TouchContainer $slidePadding={padding} $inContainer={inContainer}>
        <TouchNoWrap>
          {React.Children.map(children, (child) => (
            <TouchDiv
              $slidePadding={padding}
              $slideCount={breakpoint.count}
              $inContainer={inContainer}
              $minWidth={minWidth}
            >
              {child}
            </TouchDiv>
          ))}
        </TouchNoWrap>
      </TouchContainer>
    );
  } else {
    displayComponent = (
      <SliderStyled
        className={className}
        dots={false}
        accessibility={false}
        draggable={false}
        prevArrow={
          <PrevArrow style={fadeIn}>
            <ArrowSvg />
          </PrevArrow>
        }
        nextArrow={
          <NextArrow style={fadeIn}>
            <ArrowSvg />
          </NextArrow>
        }
        afterChange={(scrollIndex) => {
          setHasMoved(true);
          onScrolled && onScrolled({ scrollIndex, siloData });
        }}
        infinite={infinite}
        slidesToShow={breakpoint.count}
        slidesToScroll={breakpoint.count}
        $slidePadding={padding}
        $hideArrows={isTouchDevice}
      >
        {children}
      </SliderStyled>
    );
  }

  return (
    <CarouselContext.Provider value={siloData}>
      {displayComponent}
    </CarouselContext.Provider>
  );
};

export default WithAutoSizer(Carousel);
